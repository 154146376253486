// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Page two" />
    <div className="mx-auto max-w-7xl py-12 sm:px-6 lg:px-8">
      <h1 className="text-4xl tracking-tight font-extrabold text-gray-700 sm:text-5xl md:text-6xl dark:text-gray-200">
        About
      </h1>
      <p className="mt-10 text-white">This has been a long time coming.</p>
      <p className="mt-5 text-white">Stay tuned while we get setup.</p>
    </div>
  </Layout>
)

export default SecondPage
